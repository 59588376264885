@import "bootstrap/functions";
@import "variables";
@import "bootstrap/bootstrap";
//@import "bootstrap/scss/variables";
@import "bootstrap/mixins";
//.content {
//  font-size: $custom-font-size;
//}

/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  //padding-top: 3rem;
  //padding-bottom: 3rem;
  color: #5a5a5a;
}

/* navbar
-------------------------------------------------- */
#navbar {
  transition: 1.5s; /* Adds a transition effect when the navbar is changed */
}

.navbar-brand {
  font-family: 'Permanent Marker', cursive;
}

/* card components 
---------------------------------------------------*/
div.card:hover {
  border-color: $gray-500;
}

/* front-page
-------------------------------------------------- */
body, html {
  height:100%;
}

[id] {
  scroll-margin-top: 2em;
}

.front-page {
  background-image: url(https://dpyyvkdkr8dej.cloudfront.net/site/originals/IMG_4498.gif);
  height: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;

  .hero {
    margin: 1.25rem;
    font-family: "Permanent Marker", cursive;

    @media (min-width:0) {
      h1 { font-size: 2.5rem; }
      h3 { font-size: 1rem; }
    }

    @media (min-width:576px) {
      h1 { font-size: 3.5rem; }
      h3 { font-size: 1.4rem; }
    }

    @media (min-width:768px) {
      h1 { font-size: 3.5rem; }
      h3 { font-size: 1.4rem; }
    }

    @media (min-width:1200px) {
      h1 { font-size: 5rem; }
      h3 { font-size: 2rem; }
    }

    @media (min-width:1400px) {
      h1 { font-size: 7rem; }
      h3 { font-size: 2.8rem; }
    }


  }
}
/* banner image
-------------------------------------------------- */
.banner {
  margin-bottom: 25px;

  .container {
    position: relative;
    bottom: 25px;
  }
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  xmargin-bottom: 1.5rem;
  xmargin-top: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}

.marketing .row {
  margin-top: 2rem;
  margin-bottom: -1.5rem;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 3rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 2rem;
  }
}

/* POST PAGES
-------------------------------------------------- */
img.img-thumbnail:hover {
  border-color: darkgrey;
} 
